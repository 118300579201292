import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import MerchantIdDisplay from './merchantDisplay';
import '../index.css';

const Sidebar = ({ handleLogout }) => {

  useEffect(() => {
    console.log("Sidebar mounted");

    return () => {
      console.log("Sidebar unmounted");
    };
  }, []);

  return (
    <div className="sidebar">
      <MerchantIdDisplay /> {/* Display Merchant ID in Sidebar */}
      <nav>
        <ul>
          <li key="home" data-label="Home">
            <Link to="/home" className="full-clickable">
              <img src="img/dashboard/home.png" alt="Home" />
            </Link>
          </li>
          <li key="transactions" data-label="Transactions">
            <Link to="/transactions" className="full-clickable">
              <img src="img/dashboard/transactions.png" alt="Transactions" />
            </Link>
          </li>
        </ul>
      </nav>
      <div className="sidebar-footer">
        <div className="footer-item" data-label="Logout">
          {/* Use button for logout */}
          <button onClick={handleLogout} className="logout-button">
            <img src="img/icons/logout.png" alt="Logout" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;