import React, { useState, useEffect, useCallback } from 'react';
import { Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import axios from './axiosSetup';
import LoginRegister from './components/auth';
import Sidebar from './components/sidebar';
import Home from './components/home';
import ProtectedRoute from './components/protectedRoute';
import Spinner from './components/spinner';
import Confirmation from './components/confirmation';
import Transactions from './components/transactions';
import ErrorBoundary from './components/errorBoundary';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  // Logout handler
  const handleLogout = useCallback(async () => {
    try {
      console.log("Logout button clicked");
      await axios.post('/api/auth/logout');
      localStorage.removeItem('token');
      setIsAuthenticated(false); 

      setTimeout(() => {
        navigate('/auth', { replace: true });
      }, 100);
    } catch (error) {
      console.error('Logout failed:', error);
    }
  }, [navigate]);

  
  useEffect(() => {
    const checkAuth = async () => {
      setIsCheckingAuth(true);
      try {
        const response = await axios.get('/api/auth/check');
        if (response.status === 200) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
          navigate('/auth', { replace: true });
        }
      } catch (error) {
        setIsAuthenticated(false);
        navigate('/auth', { replace: true });
      } finally {
        setIsCheckingAuth(false);
      }
    };

    checkAuth();
  }, [navigate]);

  
  if (isCheckingAuth) {
    return <Spinner />;
  }

  
  const isAuthPage = location.pathname === '/auth' || location.pathname === '/confirmation';

  return (
    <div className={isAuthPage ? 'auth-background' : 'logged-in-background'}>
      {/* Conditionally render the Sidebar only when authenticated */}
      {isAuthenticated && (
        <ErrorBoundary>
          <Sidebar handleLogout={handleLogout} />
        </ErrorBoundary>
      )}

      <Routes>
        <Route path="/" element={<Navigate to={isAuthenticated ? "home/" : "/auth"} />} />
        <Route path="/auth" element={<LoginRegister onLogin={() => setIsAuthenticated(true)} />} />
        <Route path="/confirmation" element={<Confirmation />} />

        {/* Protected routes */}
        <Route
          path="/home"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route
          path="/transactions"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <Transactions />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<Navigate to={isAuthenticated ? "/" : "/auth"} />} />
      </Routes>
    </div>
  );
};

export default App;