import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from '../axiosSetup';
import './auth.css';

const LoginRegister = ({ onLogin }) => {
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [formData, setFormData] = useState({
    business_name: '',
    name: '',
    email: '',
    phone_number: '',
    password: '',
  });
  const [message, setMessage] = useState('');

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get('verified') === 'true' && isLogin) {
      setMessage('Email approved. You can now log in.');
    }
  }, [location, isLogin]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLogin) {
      try {
        const response = await axios.post('/api/auth/login', { email, password });
        alert('Login successful!');
        onLogin();
        navigate('/home');
      } catch (error) {
        console.error('Error logging in:', error);
        alert('Failed to login. Please check your credentials.');
      }
    } else {
      try {
        await axios.post('/api/auth/register', formData);
        navigate('/confirmation');
    } catch (error) {
        console.error('Error registering:', error);
        alert('Failed to register. Please try again.');
    }
  }
};

  const handleToggle = () => {
    setIsLogin(!isLogin);
    setMessage('');
  };

  return (
    <div className={`container ${isLogin ? '' : 'active'}`}>
      <div className="form-container sign-in">
        <form onSubmit={handleSubmit}>
          <h2>Login</h2>
          {isLogin && message && <p style={{ color: 'green' }}>{message}</p>}
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button type="submit">Login</button>
        </form>
      </div>
      <div className="toggle-container">
        <div className="toggle">
          <div className={`toggle-panel toggle-right ${isLogin ? 'active' : ''}`}>
            <h1>Welcome back!</h1>
            <p style={{ marginBottom: '4rem' }}>To access your backoffice space please login with your personal info.</p>
            <p>Don't have an account?</p>
            <p>Contact us</p>
            <p>finance@zenpay.cloud</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginRegister;