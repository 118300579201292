import React from 'react';
import './auth.css';

const Confirmation = () => {
  return (
    <div className="confirmation-container">
      <h1>Welcome to ZenPay</h1>
      <p>Please confirm your email address by clicking the link you've received.</p>
      <p>Check your inbox. If you don't see our email, check your spam folder.</p>
    </div>
  );
};

export default Confirmation;