import React, { useEffect, useState } from 'react';
import axios from '../axiosSetup';
import '../index.css';

const MerchantIdDisplay = () => {
  const [merchantId, setMerchantId] = useState(null);

  useEffect(() => {
    const fetchMerchantId = async () => {
      try {
        const response = await axios.get('/api/merchant-id');
        setMerchantId(response.data.merchantId);
      } catch (error) {
        console.error('Error fetching merchant ID:', error);
      }
    };

    fetchMerchantId();
  }, []);

  return (
    <div className="merchant-id-container">
      <p>Your Merchant ID: <span className="bold">{merchantId ? merchantId : 'Loading...'}</span></p>
    </div>
  );
};

export default MerchantIdDisplay;