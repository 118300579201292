import React, { useState, useEffect } from 'react';
import axios from '../axiosSetup';

const Transactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [filters, setFilters] = useState({
    internal_transaction_id: '',
    status: '',
  });
  const [merchantId, setMerchantId] = useState(null);
  const [isFiltering, setIsFiltering] = useState(false);

  useEffect(() => {
    const fetchMerchantId = async () => {
      try {
        const response = await axios.get('/api/merchant-id');
        setMerchantId(response.data.merchantId);
      } catch (error) {
        console.error('Error fetching merchant ID:', error);
      }
    };

    fetchMerchantId();
  }, []);

  const parseDate = (dateString) => {
    const [datePart, timePart] = dateString.split(' ');
    const [day, month, year] = datePart.split('/');
    const [hours, minutes, seconds] = timePart.split(':');
    return new Date(year, month - 1, day, hours, minutes, seconds);
  };

  const formatNumber = (num) => {
    if (!num) return '0.00';
    const normalized = num.replace(',', '.');
    const parsed = parseFloat(normalized);
    if (isNaN(parsed)) return '0.00';
    return parsed.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).replace(/,/g, "'");
  };

  const fetchTransactions = async () => {
    try {
      if (merchantId) {
        const response = await axios.get('/api/transactions', {
          params: { merchant_id: merchantId },
        });

        let filteredData = response.data;

        if (filters.internal_transaction_id) {
          filteredData = filteredData.filter((transaction) =>
            transaction.internal_transaction_id.includes(filters.internal_transaction_id)
          );
        } else if (filters.status) {
          filteredData = filteredData.filter(
            (transaction) => transaction.statuts === filters.status
          );
        }

        setTransactions(filteredData);
      }
    } catch (error) {
      console.error('Error fetching transactions:', error);
    }
  };

  useEffect(() => {
    fetchTransactions();
  }, [merchantId]);

  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  const handleSearch = () => {
    if (filters.internal_transaction_id && filters.status) {
      alert('Please use only one filter at a time.');
      return;
    }
    setIsFiltering(true);
    fetchTransactions();
  };

  const handleClearFilters = () => {
    setFilters({
      internal_transaction_id: '',
      status: '',
    });
    setIsFiltering(false);
    fetchTransactions();
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    alert('Transaction ID copied to clipboard');
  };

  return (
    <div className="transactions-container-m">
      <div className="transactions-header-m">
        <div className="navigation-links">
          <a href="/transactions" className="nav-link">Transactions</a>
        </div>
      </div>

      <div className="filters-m">
        <input
          type="text"
          name="internal_transaction_id"
          placeholder="Transaction ID"
          value={filters.internal_transaction_id}
          onChange={handleFilterChange}
        />
        <select name="status" value={filters.status} onChange={handleFilterChange}>
          <option value="">All Statuses</option>
          <option value="Accepted">Accepted</option>
          <option value="Canceled">Canceled</option>
          <option value="Refused">Refused</option>
        </select>
        <button className="clear-button" onClick={handleClearFilters}>Clear</button>
        <button className="search-button" onClick={handleSearch}>Search</button>
      </div>

      <div className="transactions-list-table-container-m">
        {transactions.length > 0 ? (
          <table className="transactions-list-table-m">
            <thead>
              <tr>
                <th>DATE</th>
                <th>TRANSACTION ID</th>
                <th>STATUS</th>
                <th>NAME</th>
                <th>EMAIL</th>
                <th>COUNTRY</th>
                <th>AMOUNT</th>
              </tr>
            </thead>
            <tbody>
              {transactions.map((transaction) => (
                <tr key={transaction.internal_transaction_id}>
                  <td
                    data-fulltext={transaction.paiement_date}
                    title={transaction.paiement_date}
                  >
                    {parseDate(transaction.paiement_date).toLocaleDateString('en-US', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                    })}{' '}
                    {parseDate(transaction.paiement_date).toLocaleTimeString('en-US', {
                      hour: '2-digit',
                      minute: '2-digit',
                      second: '2-digit',
                    })}
                  </td>
                  <td
                    data-fulltext={transaction.internal_transaction_id}
                    title={transaction.internal_transaction_id}
                  >
                    <img
                      src="img/icons/copy.png"
                      alt="Copy Transaction ID"
                      className="copy-icon"
                      onClick={() => copyToClipboard(transaction.internal_transaction_id)}
                    />
                    {transaction.internal_transaction_id}
                  </td>
                  <td
                    data-fulltext={transaction.statuts}
                    title={transaction.statuts}
                  >
                    {transaction.statuts}
                  </td>
                  <td
                    data-fulltext={`${transaction.firstname} ${transaction.lastname}`}
                    title={`${transaction.firstname} ${transaction.lastname}`}
                  >
                    {`${transaction.firstname} ${transaction.lastname}`}
                  </td>
                  <td
                    data-fulltext={transaction.email}
                    title={transaction.email}
                  >
                    {transaction.email}
                  </td>
                  <td
                    data-fulltext={transaction.country}
                    title={transaction.country}
                  >
                    {transaction.country}
                  </td>
                  <td
                    data-fulltext={transaction.amount}
                    title={transaction.amount}
                  >
                    ${formatNumber(transaction.amount)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div>No transactions found for the given filters.</div>
        )}
      </div>
    </div>
  );
};

export default Transactions;