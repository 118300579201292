import React, { useState, useEffect, useRef } from 'react';
import Calendar from 'react-calendar';
import axios from '../axiosSetup';

const HomePage = () => {
  const today = new Date();
  const lastWeek = new Date();
  lastWeek.setDate(today.getDate() - 6);

  const [merchantId, setMerchantId] = useState(null);
  const [dateRange, setDateRange] = useState([lastWeek, today]);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [stats, setStats] = useState({
    totalTransactions: 0,
    conversionRate: 0,
    totalProcessedAmount: 0,
  });
  const [weeklyData, setWeeklyData] = useState([]);
  const calendarRef = useRef(null);

  useEffect(() => {
    const fetchMerchantId = async () => {
      try {
        const response = await axios.get('/api/merchant-id');
        setMerchantId(response.data.merchantId);
      } catch (error) {
        console.error('Error fetching merchant ID:', error);
      }
    };

    fetchMerchantId();
  }, []);

  const parseDate = (dateString) => {
    const [datePart, timePart] = dateString.split(' ');
    const [day, month, year] = datePart.split('/');
    const [hours, minutes, seconds] = timePart.split(':');
    return new Date(year, month - 1, day, hours, minutes, seconds);
  };

  const parseAmount = (amountString) => {
    if (!amountString) return 0;
    const normalizedAmount = amountString.replace(',', '.');
    const parsedAmount = parseFloat(normalizedAmount);
    return isNaN(parsedAmount) ? 0 : parsedAmount;
  };

  const formatNumber = (num) => {
    return num.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).replace(/,/g, "'");
  };

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        if (merchantId) {
          const response = await axios.get('/api/transactions', {
            params: { startDate: dateRange[0], endDate: dateRange[1], merchant_id: merchantId },
          });
          processWeeklyData(response.data);
          calculateStats(response.data);
        }
      } catch (error) {
        console.error('Error fetching transactions:', error);
      }
    };

    const processWeeklyData = (transactions) => {
      const initialData = {
        Sunday: { totalAmount: 0, successAmount: 0, declinedAmount: 0 },
        Monday: { totalAmount: 0, successAmount: 0, declinedAmount: 0 },
        Tuesday: { totalAmount: 0, successAmount: 0, declinedAmount: 0 },
        Wednesday: { totalAmount: 0, successAmount: 0, declinedAmount: 0 },
        Thursday: { totalAmount: 0, successAmount: 0, declinedAmount: 0 },
        Friday: { totalAmount: 0, successAmount: 0, declinedAmount: 0 },
        Saturday: { totalAmount: 0, successAmount: 0, declinedAmount: 0 },
      };

      transactions.forEach((transaction) => {
        const transactionDate = parseDate(transaction.paiement_date);
        const day = transactionDate.toLocaleDateString('en-US', { weekday: 'long' });
        const amount = parseAmount(transaction.amount);

        initialData[day].totalAmount += amount;

        if (transaction.statuts === 'Accepted') {
          initialData[day].successAmount += amount;
        } else if (transaction.statuts === 'Refused') {
          initialData[day].declinedAmount += amount;
        }
      });

      setWeeklyData(Object.entries(initialData).map(([day, data]) => ({
        day,
        totalAmount: formatNumber(data.totalAmount),
        successAmount: formatNumber(data.successAmount),
        declinedAmount: formatNumber(data.declinedAmount),
      })));
    };

    const calculateStats = (transactions) => {
      const totalTransactions = transactions.length;
      const completedTransactions = transactions.filter(txn => txn.statuts === 'Accepted').length;
      const totalProcessedAmount = transactions.reduce((sum, txn) => {
        return txn.statuts === 'Accepted' ? sum + parseAmount(txn.amount) : sum;
      }, 0);

      setStats({
        totalTransactions,
        conversionRate: totalTransactions ? ((completedTransactions / totalTransactions) * 100).toFixed(2) : 0,
        totalProcessedAmount: formatNumber(totalProcessedAmount),
      });
    };

    if (merchantId) {
      fetchTransactions();
    }
  }, [merchantId, dateRange]);

  const handleDateChange = (range) => {
    setDateRange(range);
    setIsCalendarOpen(false);
  };

  const handleClickOutside = (event) => {
    if (calendarRef.current && !calendarRef.current.contains(event.target)) {
      setIsCalendarOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="home-page">
      <div className="date-picker-container" ref={calendarRef}>
        <button onClick={() => setIsCalendarOpen(!isCalendarOpen)} className="date-picker-button">
          <img src="img/icons/calendar.png" alt="Calendar" />
          {`${dateRange[0].toLocaleDateString()} - ${dateRange[1].toLocaleDateString()}`}
        </button>
        {isCalendarOpen && (
          <div className={`calendar-wrapper ${isCalendarOpen ? 'react-calendar-animate' : ''}`}>
            <Calendar
              selectRange={true}
              onChange={handleDateChange}
              value={dateRange}
              className="react-calendar--open"
            />
          </div>
        )}
      </div>

      <div className="stats-container">
        <div className="stat-box"><span>{stats.totalTransactions}</span> Transactions</div>
        <div className="stat-box"><span>{stats.conversionRate}%</span> Conversion</div>
        <div className="stat-box-a">
          <span>${stats.totalProcessedAmount}</span> Processed this week
        </div>
      </div>

      <div className="transactions-table-container">
        <table className="transactions-table">
          <thead>
            <tr>
              <th>DATE</th>
              <th>TRANSFERS</th>
              <th>SUCCESS TRANSFERS</th>
              <th>DECLINED TRANSFERS</th>
            </tr>
          </thead>
          <tbody>
            {weeklyData.map((day, index) => (
              <tr key={index}>
                <td>{day.day === new Date().toLocaleDateString('en-US', { weekday: 'long' }) ? 'Today' : day.day}</td>
                <td>${day.totalAmount}</td>
                <td>${day.successAmount}</td>
                <td>${day.declinedAmount}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <th>TOTAL</th>
              <th>${formatNumber(weeklyData.reduce((sum, day) => sum + parseAmount(day.totalAmount), 0))}</th>
              <th>${formatNumber(weeklyData.reduce((sum, day) => sum + parseAmount(day.successAmount), 0))}</th>
              <th>${formatNumber(weeklyData.reduce((sum, day) => sum + parseAmount(day.declinedAmount), 0))}</th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default HomePage;